.cm-message-container {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, 0);

  .cm-message {
    margin: 5px;
    padding: 6px 12px;
    border: var(--primary-color) solid 2px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: var(--main-text);
    animation: cmmessageshow 0.15s ease;

    .anticon {
      margin-right: 6px;
    }

    &.info .anticon {
      color: #15b1ef;
    }
    &.success .anticon {
      color: #4dd10b;
    }
    &.warn .anticon {
      color: #efab0f;
    }
    &.error .anticon {
      color: #ff4646;
    }
  }
}

@keyframes cmmessageshow {
  0% {
    transform: translate(0, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}