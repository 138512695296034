/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 27, 2021 */



@font-face {
    font-family: 'tt_hoves';
    src: url('tt_hoves_demibold-webfont.woff2') format('woff2'),
         url('tt_hoves_demibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'tt_hoves';
    src: url('tt_hoves_light-webfont.woff2') format('woff2'),
         url('tt_hoves_light-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;

}




@font-face {
    font-family: 'tt_hoves';
    src: url('tt_hoves_medium-webfont.woff2') format('woff2'),
         url('tt_hoves_medium-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}