header {
  padding: 6px 0;
}

header .container .menu {
  display: none;
}

header .container .opt a {
  padding: 6px 24px;
}

header .container .opt .learn {
  margin-right: 12px;
}

#pageContent {
  padding-top: 24px;
}
