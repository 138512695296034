#home .cards {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 12px;
}

#home .cards .nft-card .img {
  height: auto;
}

#home .cards .nft-card .tid {
  font-size: 24px;
}

#home .not-fixed-driver {
  display: block;

  .current-card {
    width: auto;
  }
  .cards {
    width: auto;
  }
}

#home .current-card {
  margin-bottom: 50px;
}

#home .current-card .nft-card .img {
  height: 300px;
}

#home .current-card .opts {
  margin-top: 10px;
  font-size: 14px;
  min-width: 190px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  &>div {
    margin-top: 0;
  }
}

#home .fixed-driver {
  grid-template-columns: repeat(2, 1fr);
}

.nft-add-word-modal .content {
  padding: 0;
}

.nft-add-word-modal .new-word .inputs {
  width: 100%;
}

.nft-add-word-modal .new-word .radio > div > span {
  font-size: 14px;
}

.nft-image-modal {
  width: 95vw;

  img {
    width: 100%;
  }
}
