.common-page-title {
  font-size: 48px;
  text-transform: uppercase;
  margin-bottom: 40px;
  position: relative;

  &.size-1 {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  span.sub {
    font-size: 30px;

    &::before {
      content: "/";
      margin-left: 20px;
      margin-right: 6px;
    }
  }

  &.size-2 {
    font-size: 30px;
    text-align: left;

    span.sub {
      font-size: 20px;
    }

    span.sub::before {
      margin-left: 16px;
    }
  }

  &.size-3 {
    font-size: 32px;
    text-align: left;

    span.sub {
      font-size: 16px;
    }

    span.sub::before {
      margin-left: 12px;
    }
  }

  .extra {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }

  &.size-1 .extra {
    padding-bottom: 20px;
  }
}


@media screen and (max-width: 768px) {
  .common-page-title {
    font-size: 40px;
    padding-bottom: 36px;

    span {
      display: block;
    }

    span.sub {
      font-size: 20px;
    }
  }
  .common-page-title span.sub::before {
    display: none;
  }

  .common-page-title.size-2 {
    font-size: 30px;
    text-align: center;

    span.sub {
      font-size: 15px;
    }
  }

  .common-page-title .extra {
    position: static;
    transform: none;
  }
}

