@import url(./assets/font/tthoves/stylesheet.css);
@import url(./assets/font/ChronicleDisplay-Roman/stylesheet.css);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
    height: 100%;
    position: relative;
    font-size: 14px;
    color: var(--main-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.root {
  height: 100%;
}

.container {
  max-width: 1440px;
  padding: 0 20px;
  margin: auto;

  &.sm {
    max-width: 640px;
  }
}

.single-page {
  padding-top: 100px;
  min-height: calc(100vh - 40px);
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
