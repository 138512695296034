.cm-modal-title {
  .common-page-title {
    margin-bottom: 0;
  }
}

#home .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  column-gap: 24px;
  row-gap: 24px;
  max-height: 720px;
  overflow: auto;

  .nft-card {
    border: transparent solid 1px;
    cursor: pointer;

    &.active,
    &:hover {
      border-color: #000;
    }
  }
}

#home .not-fixed-driver {
  display: flex;
  align-items: flex-start;
  column-gap: 150px;
  margin-bottom: 150px;

  .current-card {
    flex-grow: 1;
    flex-shrink: 1;
    width: 40%;
  }
  .cards {
    flex-grow: 1;
    flex-shrink: 1;
    width: 60%;
  }
}

#home .fixed-driver {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  column-gap: 24px;
  row-gap: 24px;

  .nft-card {
    .tid {
      // display: none;
      font-size: smaller;
    }

    .img {
      height: 200px;
    }
  }
}

#home .cards-slide {
  padding: 0 20px;

  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
  .slick-prev,
  .slick-next {
    z-index: 3;
    height: 20px;
    width: 20px;
    transform: translate(0, calc(-50% - 10px));

    &::before {
      color: #000;
      font-size: 20px;
    }
  }


  .nft-card {
    margin: 0 6px;

    .img {
      height: 100px;
    }
    .tid {
      font-size: 14px;
    }
  }
}

#home .current-card {
  .nft-card {
    .img {
      height: 500px;
    }
  }
  .opts {
    margin-top: 50px;
    font-size: 20px;

    .disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: not-allowed;
    }

    a {
      cursor: pointer;
    }

    &>div {
      margin-top: 10px;
    }
    .anticon {
      margin-right: 6px;
    }
    a>span:last-child {
      span {
        margin-right: 4px;
      }
    }
  }
}

#home .nft-card {
  min-width: 0;

  .img {
    position: relative;
    height: 300px;
    width: 100%;
    overflow: hidden;

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.4);
      color: #fff;
      text-align: center;
      padding-top: 120px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(2.8);
      transform-origin: 28% 29%;
    }
  }
  .tid {
    font-size: 30px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    .disabled {
      opacity: 0.6;
      pointer-events: none;
      cursor: not-allowed;
    }

    .anticon {
      cursor: pointer;
    }
  }
}

#home .empty {
  font-size: 20px;
  text-align: center;
  font-family: "Chronicle Display";
  opacity: 0.5;
}

.nft-fix-modal .content {
  .desc {
    text-align: center;
    margin-bottom: 24px;
  }
  .opts {
    text-align: center;

    button {
      height: 35px;
      width: 86px;
      padding: 6px 24px;
      border-radius: 3px;
      cursor: pointer;
      text-decoration: none;
      background-color: #25282A;
      color: #fff;
      border: none;

      &:first-child {
        margin-right: 50px;
      }

      img {
        height: 20px;
      }

      &:disabled {
        opacity: 0.5;
      }
      &.cancel {
        background-color: #D6D2C4;
        color: #000;
      }
    }
  }
}

.nft-add-word-modal {
  width: 450px;
  max-width: 95%;

  .content {
    padding: 0 30px;
  }

  .new-word {
    margin-bottom: 36px;

    .radio {
      font-size: 16px;
      margin-bottom: 36px;
      cursor: pointer;

      &>div {
        line-height: 22px;
        color: #fff;
        display: flex;
        margin-bottom: 12px;

        &::before {
          flex-grow: 0;
          flex-shrink: 0;
          content: "";
          display: inline-block;
          height: 22px;
          width: 22px;
          vertical-align: middle;
          border: #777 solid 1px;
          background-color: transparent;
          margin-right: 12px;
          border-radius: 3px;
        }

        &:hover::before {
          background-color: #777;
        }
        &.active::before {
          border-color: #000;
          background-color: #000;
        }

        &>span {
          padding: 0 6px;
          border-radius: 3px;
          flex-grow: 1;
          flex-shrink: 1;
          display: inline-block;
          background-color: #000;
        }
      }
    }

    .inputs {
      width: 85%;
      margin: auto;
    }

    .input {
      width: 100%;
      margin-bottom: 6px;
      position: relative;
      font-size: 16px;
      margin-top: 24px;

      span {
        display: inline-block;
        width: 30%;
      }
      input {
        width: 70%;
        border: #a5a5a5 solid 2px;
        outline: none;
        font-size: 14px;
        height: 32px;

        &>* {
          vertical-align: middle;
        }
      }

      &.error {
        span {
          color: red;
        }

        input {
          border-color: red;
        }

        &::after {
          content: attr(data-error);
          display: block;
          white-space: nowrap;
          position: absolute;
          top: 100%;
          right: 0;
          font-size: 12px;
          line-height: 12px;
          color: red;
        }
      }
    }
  }

  .opts {
    text-align: center;

    button {
      height: 35px;
      width: 86px;
      padding: 6px 24px;
      border-radius: 3px;
      cursor: pointer;
      text-decoration: none;
      background-color: #25282A;
      color: #fff;
      border: none;

      &:first-child {
        margin-right: 50px;
      }

      img {
        height: 20px;
      }

      &:disabled {
        opacity: 0.5;
      }
      &.cancel {
        background-color: #D6D2C4;
        color: #000;
      }
    }
  }
}

#home {
  .buttons-h-stack {
    display: flex;
    *:not(:last-child) {
      margin-right: 30px;
    }
  }

  .page-title-button {
    padding: 6px 24px;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    background-color: #25282A;
    color: #fff;
    border: none;
    white-space: nowrap;
    font-size: 18px;
    height: 37px;
  }

  .more {
    margin-top: 40px;
    text-align: center;
    font-size: 20px;

    img {
      height: 150px;
      margin: 24px 0 12px;
    }

    .qrcode-stack {
      display: flex;
      justify-content: center;
      *:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  @import './style.m.scss';
}
