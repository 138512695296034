.button {
    padding: 6px 24px;
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    background-color: #25282A;
    color: #fff;
    border: none;
    white-space: nowrap;
    font-size: 18px;
    height: 37px;
}

.button.outline {
    color: #25282A;
    border: 1px solid #25282A;
    background-color: #fff;
}

.button.full-width {
    width: 100%;
}

.input {
    padding: 10px 15px;
    border: 1px solid #25282A;
    border-radius: 6px;
}

.airdrop-container {
    display: grid;
    grid-template-columns: 140px 1fr;
    grid-gap: 10px;
    white-space: nowrap;
    text-align: right;
    font-size: 16px;
    align-items: center;
}

.airdrop-container :nth-child(even) {
    text-align: left;
}
